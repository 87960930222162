@import url("https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap");

:root {
  --font-weight: 300;
  --font-family: "Kaushan Script";
}

* {
  font-weight: var(--font-weight) !important;
}
html {
  overflow-x: hidden;
}
/* h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
form {
  font-weight: var(--font-weight) !important;
} */

a,
img {
  -webkit-user-drag: none;
  user-select: none;
  -webkit-user-select: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  -webkit-user-select: none;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

footer {
  margin-top: 3rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  max-width: 100vw;
}

footer a {
  color: inherit !important;
}

img {
  border-radius: 8px;
}

section {
  padding-top: 60px;
}

.active {
  text-decoration: underline !important;
  text-decoration-thickness: 1.5px !important;
  text-underline-offset: 4px;
}

.active-page {
  border-bottom: 1px solid #000;
}

.navbar-brand {
  text-decoration: none !important;
  font-family: var(--font-family);
}

.about-image {
  max-width: 90vw;
  max-height: 90vh;
}

.artwork {
  max-width: 80%;
  max-height: 60%;
  height: auto;
}

.carousel {
  display: flex;
  justify-content: center;
}

.carousel-slide {
  max-width: 90vw !important;
  max-height: 90vh !important;
  min-height: 80vh;
  margin-top: 1rem;
  margin-bottom: 1rem;
  z-index: 1;
}

.carousel-control-prev {
  left: -3rem !important;
}

.carousel-control-next {
  right: -3rem !important;
}

.carousel-indicators {
  bottom: -30px !important;
}

.carousel-inner {
  min-width: 90vw;
}

.category {
  font-family: var(--font-family);
  font-size: 2.5rem;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.col-center {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.contact-image {
  max-height: 80vh;
  max-width: 90vw;
}

.font {
  font-family: var(--font-family);
}

.footer-content {
  display: flex;
  margin-left: 1rem;
  margin-right: 1rem;
  max-width: 100% !important;
}

.gallery-image {
  max-width: 230px !important;
  margin-bottom: 0.25rem;
  max-height: 345px;
  /* height: 100%; */
}

.home-image {
  max-width: 90vw;
  max-height: 90vh;
}

.home-row {
  display: flex;
  justify-content: space-evenly;
}

.home-row-image {
  max-height: 40vh;
}

.home-gallery-sm {
  max-width: 18vw;
  max-height: 18vh;
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.home-gallery-sm:hover {
  cursor: pointer;
}

.home-gallery-wrapper {
  position: absolute;
  top: 28vh;
  left: 84vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-slice-end {
  display: flex;
  justify-content: end;
}

.home-splash {
  max-width: 100vw;
}

.lightbox {
  background-color: rgba(0, 0, 0, 0.75);
}

.lightbox-body {
  /* width: 90vw; */
}

.page-numbers {
  cursor: pointer;
  font-size: 1.3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.heading {
  text-align: center;
  padding-bottom: 1rem;
  padding-top: 1rem;
  font-family: var(--font-family);
  font-size: 3rem;
}

.lightbox-slide {
  max-height: 84vh;
  max-width: 100%;
}

.nav-link {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  font-weight: 300;
}

.navbar-toggler {
  border: none !important;
}

.selected-home {
  border: 3px solid black;
}

.shareBtn:hover {
  cursor: pointer;
}

.share-icon {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.subheading {
  text-align: center;
  margin-bottom: 0.75rem;
  margin-top: 0.75rem;
}

.tag-line {
  font-weight: 300;
}

.text-side {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
  justify-content: center;
  letter-spacing: 0.35px;
  word-spacing: 10px;
}

.signature {
  font-weight: 300;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.vcenter {
  display: flex;
  align-items: center;
  height: 100%;
}

@media screen and (max-width: 968px) {
  .carousel-control-prev {
    display: none !important;
  }
  .carousel-control-next {
    display: none !important;
  }
  .contact-image {
    max-height: 400px;
    order: 1;
  }
  .contact-content {
    order: 2;
  }
  .home-gallery-wrapper {
    position: static;
    display: block;
    align-items: center;
    margin-right: 1rem;
  }

  .order2 {
    order: 1;
    margin-bottom: 1rem;
  }

  .order1 {
    order: 2;
  }

  .order3 {
    order: 3;
  }
  .home-image {
    max-width: 60vw !important;
    max-height: 60vh !important;
  }
}

@media screen and (max-width: 1200px) {
  .order2 {
    order: 1;
    margin-bottom: 1rem;
  }

  .order1 {
    order: 2;
  }

  .order3 {
    order: 3;
  }
  .home-image {
    max-width: 80vw;
    max-height: 80vh;
  }
}
